<template>
   <Movimientos />
</template>

<script>
import Movimientos from "../components/Movimientos/Movimientos.vue";

export default {
   components: {
      Movimientos,
   },
};
</script>

<style></style>
