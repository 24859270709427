<template>
   <div class="ma-6">
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Dialog message="¿Desea cancelar el movimiento?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <ExcelExport ref="excelexport" />
      <v-data-table
         :loading="isloading"
         :headers="headers"
         :items="$store.state.movimientos.items"
         :items-per-page="15"
         class="elevation-1"
         item-key="movimiento_id"
         :options.sync="options"
         :page.sync="page"
         :server-items-length="totalItems"
         :footer-props="{
            'items-per-page-options': [10, 30, 50, 100],
         }"
         :item-class="itemRowBackground"
         :expanded.sync="expanded"
         single-expand
         show-expand
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn v-if="$permiso('exportar_movimientos', false)" @click="excel()" class="ml-2"
                  ><v-icon left>mdi-microsoft-excel</v-icon>Exportar</v-btn
               >
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                  @input="search = search.slice(0, 30)"
               ></v-text-field>
            </v-toolbar>
         </template>
         <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="expand-container">
               <v-container style="margin: 0; max-width: unset">
                  <v-row v-if="item.consecutivo">
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Subcuenta</v-col>
                     <v-col class="expand-content">{{ `${item.cuenta}-${item.consecutivo}` }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Inicial</v-col>
                     <v-col class="expand-content">{{ "$" + item.inicial }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Agregado</v-col>
                     <v-col class="expand-content">{{ "$" + item.agregado }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Descontado</v-col>
                     <v-col class="expand-content">{{ "$" + item.descontado }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Final</v-col>
                     <v-col class="expand-content">{{ "$" + item.final }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Comision</v-col>
                     <v-col class="expand-content">{{ "$" + item.comision }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Referencia</v-col>
                     <v-col class="expand-content">{{ item.referencia }}</v-col>
                  </v-row>
               </v-container>
            </td>
         </template>
         <template v-slot:[`item.tipo`]="{ item }">
            <v-icon small color="success" v-if="item.tipo == 'D'">mdi-piggy-bank</v-icon>
            <v-icon small color="error" v-if="item.tipo == 'R'">mdi-cash-fast</v-icon>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" small v-bind="attrs" v-on="on" @click="cancelMov(item)">mdi-cancel</v-icon>
               </template>
               <span>Cancelar movimiento</span>
            </v-tooltip>
            <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on" @click="rePrint(item)">mdi-printer</v-icon>
               </template>
               <span>Reimprimir movimiento</span>
            </v-tooltip>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ExcelExport from "./ExcelExport.vue";
import { printTicket } from "../../modules/printTicket";

export default {
   components: {
      ExcelExport,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "movimiento_id" },
            { text: "Tipo", value: "tipo" },
            { text: "Usuario", value: "usuario" },
            { text: "Sucursal", value: "sucursal" },
            { text: "Cuenta", value: "cuenta" },
            { text: "Fecha Hora", value: "fecha_hora" },
            { text: "Actions", value: "actions" },
            { text: "", value: "data-table-expand" },
         ],
         expanded: [],
         isloading: true,
         search: "",
         totalItems: 0,
         page: 1,
         options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["movimiento_id"],
            sortDesc: [true],
         },
         itemToCancel: {},
      };
   },
   watch: {
      options: async function () {
         await this.initialize();
      },
      search: async function () {
         this.page = 1;
         await this.initialize();
      },
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getMovimientos", {
               page: this.options.page,
               itemsPerPage: this.options.itemsPerPage,
               sortBy: this.options.sortBy[0] ?? "",
               sortDesc: this.options.sortDesc[0] ?? false,
               search: this.search,
            });
            this.totalItems = +this.$store.state.movimientos.totalItems;
            localStorage.setItem("movimientos_options", JSON.stringify(this.options));
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      rePrint: function (item) {
         printTicket(item);
      },
      currency_format(num) {
         var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
         });
         return formatter.format(num);
      },
      cancelMov: function (item) {
         if (this.$permiso("cancelar_movimientos")) {
            this.itemToCancel = item;
            this.$refs.deleteItem.Show();
         }
      },
      confirmDeleteItem: async function () {
         try {
            this.isloading = true;
            let res = await this.$store.dispatch("cancelMovimiento", {
               movimiento_id: this.itemToCancel.movimiento_id,
            });

            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      itemRowBackground: function (item) {
         return item.cancelado == "S" ? "cancelado" : "";
      },
      async excel() {
         this.$refs.excelexport.Show();
      },
   },
   created() {
      try {
         if (localStorage.getItem("movimientos_options")) {
            this.options = JSON.parse(localStorage.getItem("movimientos_options"));
         } else {
            this.options = {
               page: 1,
               itemsPerPage: 10,
               sortBy: ["movimiento_id"],
               sortDesc: [true],
            };
            localStorage.setItem("movimientos_options", JSON.stringify(this.options));
         }
      } catch (error) {
         this.options = {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["movimiento_id"],
            sortDesc: [true],
         };
         localStorage.setItem("movimientos_options", JSON.stringify(this.options));
      } finally {
         this.initialize();
      }
   },
};
</script>

<style>
.expand-header {
   font-weight: 700;
}
.expand-content {
   text-align: left;
}
.cancelado {
   background-color: #da4c4c;
}
</style>
