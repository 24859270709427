var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-6"},[_c('Notification',{ref:"error_noti",attrs:{"type":"error","message":"Error"}}),_c('Notification',{ref:"ok_noti",attrs:{"type":"success","message":"Información actualizada"}}),_c('Dialog',{ref:"deleteItem",attrs:{"message":"¿Desea cancelar el movimiento?"},on:{"result_ok":function($event){return _vm.confirmDeleteItem()}}}),_c('ExcelExport',{ref:"excelexport"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isloading,"headers":_vm.headers,"items":_vm.$store.state.movimientos.items,"items-per-page":15,"item-key":"movimiento_id","options":_vm.options,"page":_vm.page,"server-items-length":_vm.totalItems,"footer-props":{
         'items-per-page-options': [10, 30, 50, 100],
      },"item-class":_vm.itemRowBackground,"expanded":_vm.expanded,"single-expand":"","show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"secondary"}},[(_vm.$permiso('exportar_movimientos', false))?_c('v-btn',{staticClass:"ml-2",on:{"click":function($event){return _vm.excel()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v("Exportar")],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-autorenew")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},on:{"input":function($event){_vm.search = _vm.search.slice(0, 30)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{staticClass:"expand-container",attrs:{"colspan":headers.length}},[_c('v-container',{staticStyle:{"margin":"0","max-width":"unset"}},[(item.consecutivo)?_c('v-row',[_c('v-col',{staticClass:"mr-4 expand-header",attrs:{"cols":"3","md":"2","lg":"1"}},[_vm._v("Subcuenta")]),_c('v-col',{staticClass:"expand-content"},[_vm._v(_vm._s(((item.cuenta) + "-" + (item.consecutivo))))])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mr-4 expand-header",attrs:{"cols":"3","md":"2","lg":"1"}},[_vm._v("Inicial")]),_c('v-col',{staticClass:"expand-content"},[_vm._v(_vm._s("$" + item.inicial))])],1),_c('v-row',[_c('v-col',{staticClass:"mr-4 expand-header",attrs:{"cols":"3","md":"2","lg":"1"}},[_vm._v("Agregado")]),_c('v-col',{staticClass:"expand-content"},[_vm._v(_vm._s("$" + item.agregado))])],1),_c('v-row',[_c('v-col',{staticClass:"mr-4 expand-header",attrs:{"cols":"3","md":"2","lg":"1"}},[_vm._v("Descontado")]),_c('v-col',{staticClass:"expand-content"},[_vm._v(_vm._s("$" + item.descontado))])],1),_c('v-row',[_c('v-col',{staticClass:"mr-4 expand-header",attrs:{"cols":"3","md":"2","lg":"1"}},[_vm._v("Final")]),_c('v-col',{staticClass:"expand-content"},[_vm._v(_vm._s("$" + item.final))])],1),_c('v-row',[_c('v-col',{staticClass:"mr-4 expand-header",attrs:{"cols":"3","md":"2","lg":"1"}},[_vm._v("Comision")]),_c('v-col',{staticClass:"expand-content"},[_vm._v(_vm._s("$" + item.comision))])],1),_c('v-row',[_c('v-col',{staticClass:"mr-4 expand-header",attrs:{"cols":"3","md":"2","lg":"1"}},[_vm._v("Referencia")]),_c('v-col',{staticClass:"expand-content"},[_vm._v(_vm._s(item.referencia))])],1)],1)],1)]}},{key:"item.tipo",fn:function(ref){
      var item = ref.item;
return [(item.tipo == 'D')?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-piggy-bank")]):_vm._e(),(item.tipo == 'R')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-cash-fast")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.cancelMov(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-cancel")])]}}],null,true)},[_c('span',[_vm._v("Cancelar movimiento")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.rePrint(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-printer")])]}}],null,true)},[_c('span',[_vm._v("Reimprimir movimiento")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }