<template>
   <v-dialog v-model="dialog" max-width="800px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>Exportar movimientos</v-card-title>
         <v-card-text>
            <form>
               <v-row>
                  <v-col cols="12" sm="7">
                     <v-date-picker class="mt-1" full-width v-model="dates" range></v-date-picker>
                  </v-col>
                  <v-col class="d-flex flex-column">
                     <v-text-field
                        v-model="cuenta"
                        label="Cuenta"
                        type="number"
                        class="inputnum"
                        clearable
                        :error-messages="cuentaErrors"
                        @input="$v.cuenta.$touch()"
                        @blur="$v.cuenta.$touch()"
                     ></v-text-field>
                     <v-select clearable v-model="usuario_id" :items="usuarios" label="Usuario" item-text="text" item-value="value"></v-select>
                     <v-select clearable v-model="sucursal_id" :items="sucursales" label="Sucursal" item-text="text" item-value="value"></v-select>
                  </v-col>
               </v-row>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import fileDownload from "js-file-download";
import moment from "moment";

export default {
   mixins: [validationMixin],
   validations: {
      cuenta: {
         integer: function () {
            if (this.cuenta) {
               return Number.isInteger(+this.cuenta);
            }
            return true;
         },
         size: function () {
            if (this.cuenta) {
               return this.cuenta.length == 8;
            }
            return true;
         },
         positivo: function () {
            if (this.cuenta) {
               return this.cuenta > 0;
            }
            return true;
         },
      },
   },
   data: function () {
      return {
         usuarios: [],
         sucursales: [],
         dates: [],
         cuenta: null,
         usuario_id: null,
         sucursal_id: null,
         dialog: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();

            if (!this.dates) return;
            if (this.dates.length != 2) return;

            if (moment(this.dates[0]).isAfter(moment(this.dates[1]))) {
               this.dates = [this.dates[1], this.dates[0]];
            }

            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;

               res = await this.$store.dispatch("exportMovimientos", {
                  cuenta: this.cuenta ?? "",
                  usuario_id: this.usuario_id ?? "",
                  sucursal_id: this.sucursal_id ?? "",
                  fecha_inicial: this.dates[0],
                  fecha_final: this.dates[1],
               });

               if(res.data){
                  fileDownload(res.data, "reporte.csv");
               } else {
                  throw res;
               }

               this.$emit("save");
               this.close();
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      Show: async function () {
         try {
            this.isloading = true;
            this.dialog = true;

            await this.$store.dispatch("getSucursales");
            await this.$store.dispatch("getUsers");

            this.dates = [];
            this.cuenta = null;
            this.usuario_id = null;
            this.sucursal_id = null;

            this.usuarios = [];
            this.$store.state.users.forEach((element) => {
               this.usuarios.push({
                  text: element.nombre,
                  value: element.user_id,
               });
            });

            this.sucursales = [];
            this.$store.state.sucursales.forEach((element) => {
               this.sucursales.push({
                  text: element.nombre,
                  value: element.sucursal_id,
               });
            });
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   computed: {
      cuentaErrors() {
         const errors = [];
         if (!this.$v.cuenta.$dirty) return errors;
         !this.$v.cuenta.size && errors.push("Debe contener 8 caracteres");
         !this.$v.cuenta.positivo && errors.push("Debe ser un numero positivo");
         !this.$v.cuenta.integer && errors.push("Indique un numero entero");
         return errors;
      },
   },
};
</script>

<style scoped>
.v-text-field >>> input {
   font-size: 25px;
}
.inputnum >>> input[type="number"] {
   -moz-appearance: textfield;
}
.inputnum >>> input::-webkit-outer-spin-button,
.inputnum >>> input::-webkit-inner-spin-button {
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}
</style>
